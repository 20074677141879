/** Generate by swagger-axios-codegen */
// @ts-nocheck
/* eslint-disable */

/** Generate by swagger-axios-codegen */
/* eslint-disable */
// @ts-nocheck
import axiosStatic, { AxiosInstance, AxiosRequestConfig } from 'axios';

export interface IRequestOptions extends AxiosRequestConfig {
  /** only in axios interceptor config*/
  loading?: boolean;
  showError?: boolean;
}

export interface IRequestConfig {
  method?: any;
  headers?: any;
  url?: any;
  data?: any;
  params?: any;
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance;
  /** only in axios interceptor config*/
  loading: boolean;
  showError: boolean;
}

// Add default options
export const serviceOptions: ServiceOptions = {};

// Instance selector
export function axios(configs: IRequestConfig, resolve: (p: any) => void, reject: (p: any) => void): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  } else {
    throw new Error('please inject yourself instance like axios  ');
  }
}

export function getConfigs(method: string, contentType: string, url: string, options: any): IRequestConfig {
  const configs: IRequestConfig = {
    loading: serviceOptions.loading,
    showError: serviceOptions.showError,
    ...options,
    method,
    url
  };
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType
  };
  return configs;
}

export const basePath = '';

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number;
  items?: T[];
}

export class PagedResultDto<T = any> implements IPagedResult<T> {
  totalCount?: number;
  items?: T[];
}

// customer definition
// empty

export class HotelsService {
  /**
   * Get all hotel attributes. Pagination and localization included
   */
  static hotelsControllerFetchHotelAttributes(
    params: {
      /** The language in which to return results */
      language: string;
      /**  */
      page?: number;
      /**  */
      lastId?: string;
      /**  */
      perPage: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HotelAttributeGroupsDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/hotels/attributes';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        language: params['language'],
        page: params['page'],
        lastId: params['lastId'],
        perPage: params['perPage']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Search hotels by attributes. Pagination and localization included
   */
  static hotelsControllerFindAll(
    params: {
      /**  */
      language: string;
      /**  */
      page?: number;
      /**  */
      lastId?: string;
      /**  */
      perPage: number;
      /**  */
      attributes?: string;
      /**  */
      countries?: string;
      /**  */
      regions?: string;
      /**  */
      cities?: string;
      /**  */
      cityDistricts?: string;
      /**  */
      includeLocation?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExtendedHotelDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/hotels/searchByAttributes';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        language: params['language'],
        page: params['page'],
        lastId: params['lastId'],
        perPage: params['perPage'],
        attributes: params['attributes'],
        countries: params['countries'],
        regions: params['regions'],
        cities: params['cities'],
        cityDistricts: params['cityDistricts'],
        includeLocation: params['includeLocation']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get single hotel. Localization included
   */
  static hotelsControllerGetHotel(
    params: {
      /**  */
      id: string;
      /**  */
      includeLocation?: boolean;
      /**  */
      language: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExtendedHotelDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/hotels/hotel/{id}';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'], includeLocation: params['includeLocation'], language: params['language'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get single hotel by slug. Localization included
   */
  static hotelsControllerGetHotelBySlug(
    params: {
      /**  */
      slug: string;
      /**  */
      language: string;
      /**  */
      includeLocation?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExtendedHotelDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/hotels/hotel/slug/{slug}';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        slug: params['slug'],
        language: params['language'],
        includeLocation: params['includeLocation']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class LocationService {
  /**
   * Search countries. Pagination included
   */
  static locationControllerFindAllCountries(
    params: {
      /** The language in which to return results */
      language: string;
      /**  */
      page?: number;
      /**  */
      lastId?: string;
      /**  */
      perPage: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExtendedLocationUnitDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/location/countries';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        language: params['language'],
        page: params['page'],
        lastId: params['lastId'],
        perPage: params['perPage']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Search regions. Pagination included
   */
  static locationControllerFindAllRegions(
    params: {
      /** The language in which to return results */
      language: string;
      /**  */
      page?: number;
      /**  */
      lastId?: string;
      /**  */
      perPage: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExtendedRegionDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/location/regions';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        language: params['language'],
        page: params['page'],
        lastId: params['lastId'],
        perPage: params['perPage']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Search cities. Pagination included
   */
  static locationControllerFindAllCities(
    params: {
      /** The language in which to return results */
      language: string;
      /**  */
      page?: number;
      /**  */
      lastId?: string;
      /**  */
      perPage: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CityDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/location/cities';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        language: params['language'],
        page: params['page'],
        lastId: params['lastId'],
        perPage: params['perPage']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Search city districts. Pagination included
   */
  static locationControllerFindAllCityDistricts(
    params: {
      /** The language in which to return results */
      language: string;
      /**  */
      page?: number;
      /**  */
      lastId?: string;
      /**  */
      perPage: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExtendedLocationUnitDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/location/cityDistricts';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        language: params['language'],
        page: params['page'],
        lastId: params['lastId'],
        perPage: params['perPage']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class HotelUnitGroupService {
  /**
   * Get all hotel unit group attributes. Pagination and localization included
   */
  static hotelUnitGroupControllerFetchHotelUnitGroupAttributes(
    params: {
      /** The language in which to return results */
      language: string;
      /**  */
      page?: number;
      /**  */
      lastId?: string;
      /**  */
      perPage: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HotelUnitGroupAttributeGroupsDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/hotel-unit-groups/attributes';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        language: params['language'],
        page: params['page'],
        lastId: params['lastId'],
        perPage: params['perPage']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get single unit group. Localization included
   */
  static hotelUnitGroupControllerGetUnitGroup(
    params: {
      /**  */
      id: string;
      /**  */
      language: string;
      /**  */
      includeLocation?: boolean;
      /**  */
      includeHotel?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExtendedHotelUnitGroupDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/hotel-unit-groups/unitGroup/{id}';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        id: params['id'],
        language: params['language'],
        includeLocation: params['includeLocation'],
        includeHotel: params['includeHotel']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Search unit groups by attributes. Pagination and localization included
   */
  static hotelUnitGroupControllerFindUnitGroups(
    params: {
      /**  */
      language: string;
      /**  */
      page?: number;
      /**  */
      lastId?: string;
      /**  */
      perPage: number;
      /**  */
      attributes?: string;
      /**  */
      hotelAttributes?: string;
      /**  */
      hotelIds?: string;
      /**  */
      unitGroupIds?: string;
      /**  */
      countries?: string;
      /**  */
      regions?: string;
      /**  */
      cities?: string;
      /**  */
      cityDistricts?: string;
      /**  */
      minArea?: number;
      /**  */
      maxArea?: number;
      /**  */
      sortOrder?: string;
      /**  */
      sortByField?: string;
      /**  */
      includeLocation?: boolean;
      /**  */
      includeHotel?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExtendedHotelUnitGroupDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/hotel-unit-groups/searchUnitGroupsByAttributes';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        language: params['language'],
        page: params['page'],
        lastId: params['lastId'],
        perPage: params['perPage'],
        attributes: params['attributes'],
        hotelAttributes: params['hotelAttributes'],
        hotelIds: params['hotelIds'],
        unitGroupIds: params['unitGroupIds'],
        countries: params['countries'],
        regions: params['regions'],
        cities: params['cities'],
        cityDistricts: params['cityDistricts'],
        minArea: params['minArea'],
        maxArea: params['maxArea'],
        sortOrder: params['sortOrder'],
        sortByField: params['sortByField'],
        includeLocation: params['includeLocation'],
        includeHotel: params['includeHotel']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get all hotel unit group view types. Pagination included
   */
  static hotelUnitGroupControllerViewTypes(
    params: {
      /** The language in which to return results */
      language: string;
      /**  */
      page?: number;
      /**  */
      lastId?: string;
      /**  */
      perPage: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExtendedHotelUnitGroupViewTypeDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/hotel-unit-groups/viewTypes';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        language: params['language'],
        page: params['page'],
        lastId: params['lastId'],
        perPage: params['perPage']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class OffersService {
  /**
   * Search offers for hotels. Pagination and localization included
   */
  static offersControllerSearchOffers(
    params: {
      /**  */
      language: string;
      /**  */
      page?: number;
      /**  */
      lastId?: string;
      /**  */
      perPage: number;
      /**  */
      attributes?: string;
      /**  */
      hotelAttributes?: string;
      /**  */
      hotelIds?: string;
      /**  */
      unitGroupIds?: string;
      /**  */
      countries?: string;
      /**  */
      regions?: string;
      /**  */
      cities?: string;
      /**  */
      cityDistricts?: string;
      /**  */
      minArea?: number;
      /**  */
      maxArea?: number;
      /**  */
      sortOrder?: string;
      /**  */
      sortByField?: string;
      /**  */
      arrivalDate: string;
      /**  */
      departureDate: string;
      /**  */
      adults: number;
      /**  */
      children?: number;
      /**  */
      minPrice?: number;
      /**  */
      maxPrice?: number;
      /**  */
      includeHotel?: boolean;
      /**  */
      includeHotelUnitGroup?: boolean;
      /**  */
      includeHotelUnitGroupLocation?: boolean;
      /**  */
      includeHotelUnitGroupHotel?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExtendedSearchOffersResponseDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/offers/searchOffers';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        language: params['language'],
        page: params['page'],
        lastId: params['lastId'],
        perPage: params['perPage'],
        attributes: params['attributes'],
        hotelAttributes: params['hotelAttributes'],
        hotelIds: params['hotelIds'],
        unitGroupIds: params['unitGroupIds'],
        countries: params['countries'],
        regions: params['regions'],
        cities: params['cities'],
        cityDistricts: params['cityDistricts'],
        minArea: params['minArea'],
        maxArea: params['maxArea'],
        sortOrder: params['sortOrder'],
        sortByField: params['sortByField'],
        arrivalDate: params['arrivalDate'],
        departureDate: params['departureDate'],
        adults: params['adults'],
        children: params['children'],
        minPrice: params['minPrice'],
        maxPrice: params['maxPrice'],
        includeHotel: params['includeHotel'],
        includeHotelUnitGroup: params['includeHotelUnitGroup'],
        includeHotelUnitGroupLocation: params['includeHotelUnitGroupLocation'],
        includeHotelUnitGroupHotel: params['includeHotelUnitGroupHotel']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get single offer for hotel. Pagination and localization included
   */
  static offersControllerGetOffer(
    params: {
      /**  */
      language: string;
      /**  */
      page?: number;
      /**  */
      lastId?: string;
      /**  */
      perPage: number;
      /**  */
      attributes?: string;
      /**  */
      hotelAttributes?: string;
      /**  */
      hotelIds?: string;
      /**  */
      unitGroupIds?: string;
      /**  */
      countries?: string;
      /**  */
      regions?: string;
      /**  */
      cities?: string;
      /**  */
      cityDistricts?: string;
      /**  */
      minArea?: number;
      /**  */
      maxArea?: number;
      /**  */
      sortOrder?: string;
      /**  */
      sortByField?: string;
      /**  */
      arrivalDate: string;
      /**  */
      departureDate: string;
      /**  */
      adults: number;
      /**  */
      children?: number;
      /**  */
      unitGroupId: string;
      /**  */
      promoCode?: string;
      /**  */
      includeHotel?: boolean;
      /**  */
      includeHotelUnitGroup?: boolean;
      /**  */
      includeHotelUnitGroupLocation?: boolean;
      /**  */
      includeHotelUnitGroupHotel?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExtendedOfferDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/offers/getOffer';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        language: params['language'],
        page: params['page'],
        lastId: params['lastId'],
        perPage: params['perPage'],
        attributes: params['attributes'],
        hotelAttributes: params['hotelAttributes'],
        hotelIds: params['hotelIds'],
        unitGroupIds: params['unitGroupIds'],
        countries: params['countries'],
        regions: params['regions'],
        cities: params['cities'],
        cityDistricts: params['cityDistricts'],
        minArea: params['minArea'],
        maxArea: params['maxArea'],
        sortOrder: params['sortOrder'],
        sortByField: params['sortByField'],
        arrivalDate: params['arrivalDate'],
        departureDate: params['departureDate'],
        adults: params['adults'],
        children: params['children'],
        unitGroupId: params['unitGroupId'],
        promoCode: params['promoCode'],
        includeHotel: params['includeHotel'],
        includeHotelUnitGroup: params['includeHotelUnitGroup'],
        includeHotelUnitGroupLocation: params['includeHotelUnitGroupLocation'],
        includeHotelUnitGroupHotel: params['includeHotelUnitGroupHotel']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get future offers. Pagination and localization included
   */
  static offersControllerFutureOffers(
    params: {
      /**  */
      language: string;
      /**  */
      page?: number;
      /**  */
      lastId?: string;
      /**  */
      perPage: number;
      /**  */
      includeHotel?: boolean;
      /**  */
      includeHotelUnitGroup?: boolean;
      /**  */
      includeHotelUnitGroupLocation?: boolean;
      /**  */
      includeHotelUnitGroupHotel?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExtendedOfferDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/offers/futureOffers';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        language: params['language'],
        page: params['page'],
        lastId: params['lastId'],
        perPage: params['perPage'],
        includeHotel: params['includeHotel'],
        includeHotelUnitGroup: params['includeHotelUnitGroup'],
        includeHotelUnitGroupLocation: params['includeHotelUnitGroupLocation'],
        includeHotelUnitGroupHotel: params['includeHotelUnitGroupHotel']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class OwnerAccountService {
  /**
   * Get unit groups
   */
  static ownerAccountControllerUnitGroups(
    params: {
      /**  */
      language: string;
      /**  */
      page?: number;
      /**  */
      lastId?: string;
      /**  */
      perPage: number;
      /**  */
      unitGroupIds?: string;
      /**  */
      includeLocation?: boolean;
      /**  */
      includeHotel?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExtendedOwnerAccountUnitGroupResponseDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/owner-account/unitGroups';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        language: params['language'],
        page: params['page'],
        lastId: params['lastId'],
        perPage: params['perPage'],
        unitGroupIds: params['unitGroupIds'],
        includeLocation: params['includeLocation'],
        includeHotel: params['includeHotel']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get single unit group. Localization included
   */
  static ownerAccountControllerGetUnitGroup(
    params: {
      /**  */
      id: string;
      /**  */
      language: string;
      /**  */
      includeLocation?: boolean;
      /**  */
      includeHotel?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExtendedOwnerAccountUnitGroupResponseDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/owner-account/unitGroup/{id}';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        id: params['id'],
        language: params['language'],
        includeLocation: params['includeLocation'],
        includeHotel: params['includeHotel']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get a list of bookings
   */
  static ownerAccountControllerBookings(
    params: {
      /**  */
      language: string;
      /**  */
      page?: number;
      /**  */
      lastId?: string;
      /**  */
      perPage: number;
      /**  */
      bookingStatus?: string;
      /**  */
      includeLocation?: boolean;
      /**  */
      includeHotel?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExtendedOwnerAccountBookingResponseDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/owner-account/bookings';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        language: params['language'],
        page: params['page'],
        lastId: params['lastId'],
        perPage: params['perPage'],
        bookingStatus: params['bookingStatus'],
        includeLocation: params['includeLocation'],
        includeHotel: params['includeHotel']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get booking
   */
  static ownerAccountControllerBooking(
    params: {
      /**  */
      bookingId: string;
      /**  */
      language: string;
      /**  */
      includeLocation?: boolean;
      /**  */
      includeHotel?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExtendedOwnerAccountBookingResponseDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/owner-account/bookings/{bookingId}';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        bookingId: params['bookingId'],
        language: params['language'],
        includeLocation: params['includeLocation'],
        includeHotel: params['includeHotel']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export interface HotelAttributeDto {
  /**  */
  id: number;

  /**  */
  content_id: number;

  /**  */
  group_id: number;

  /**  */
  title: string;

  /**  */
  description: string;

  /**  */
  code: string;

  /**  */
  priority: number;

  /**  */
  show_on_main_page?: boolean;

  /**  */
  icon: string;
}

export interface HotelAttributeGroupsDto {
  /**  */
  id: number;

  /**  */
  content_id: number;

  /**  */
  title: string;

  /**  */
  code: string;

  /**  */
  priority: number;

  /**  */
  show_on_main_page?: boolean;

  /**  */
  max_items_per_row: EnumHotelAttributeGroupsDtoMaxItemsPerRow;

  /**  */
  attributes: HotelAttributeDto[];
}

export interface MediaDtoImpl {
  /**  */
  url: string;

  /**  */
  media_type: string;
}

export interface SEOMetadataDtoImpl {
  /**  */
  title: string;

  /**  */
  description?: string;

  /**  */
  keywords: [];
}

export interface HotelAttributePrimaryDetailsDtoImpl {
  /**  */
  id: number;
}

export interface PromotionLabelDtoImpl {
  /**  */
  id: string;
}

export interface ExtendedRegionDto {
  /**  */
  id: number;

  /**  */
  title: string;

  /**  */
  content_id: number;

  /**  */
  media: string;

  /**  */
  slug: string;
}

export interface ExtendedLocationUnitDto {
  /**  */
  id: number;

  /**  */
  title: string;

  /**  */
  content_id: number;
}

export interface ExtendedLocationDto {
  /**  */
  id: string;

  /**  */
  country: string;

  /**  */
  countryId: number;

  /**  */
  region: string;

  /**  */
  regionId: number;

  /**  */
  city: string;

  /**  */
  cityId: number;

  /**  */
  cityDistrict: string;

  /**  */
  cityDistrictId: number;

  /**  */
  coordinates?: object;

  /**  */
  regionObj: ExtendedRegionDto;

  /**  */
  cityObj: ExtendedLocationUnitDto;

  /**  */
  countryObj: ExtendedLocationUnitDto;

  /**  */
  cityDistrictObj: ExtendedLocationUnitDto;
}

export interface ExtendedHotelDto {
  /**  */
  id: string;

  /**  */
  slug: string;

  /**  */
  title: string;

  /**  */
  shortDescription: string;

  /**  */
  description: string;

  /**  */
  media: MediaDtoImpl[];

  /**  */
  seoMetadata?: CombinedSeoMetadataTypes;

  /**  */
  attributes: HotelAttributePrimaryDetailsDtoImpl[];

  /**  */
  promotionLabels: PromotionLabelDtoImpl[];

  /**  */
  location: ExtendedLocationDto;

  /**  */
  locationId: string;

  /**  */
  contactNumber: string;
}

export interface CityDto {
  /**  */
  id: number;

  /**  */
  title: string;

  /**  */
  content_id: number;

  /**  */
  show_on_search: boolean;

  /**  */
  show_on_personal_cabinet: boolean;
}

export interface HotelUnitGroupAttributeDto {
  /**  */
  id: number;

  /**  */
  content_id: number;

  /**  */
  group_id: number;

  /**  */
  title: string;

  /**  */
  description: string;

  /**  */
  code: string;

  /**  */
  priority: number;

  /**  */
  full_title: string;

  /**  */
  icon: string;

  /**  */
  photo: string;

  /**  */
  show_in_main_slider: boolean;

  /**  */
  show_in_sidebar_filters: boolean;

  /**  */
  show_in_attributes_on_mini_cards: boolean;

  /**  */
  hide_on_card: boolean;

  /**  */
  show_in_personal_cabinet: boolean;
}

export interface HotelUnitGroupAttributeGroupsDto {
  /**  */
  id: number;

  /**  */
  content_id: number;

  /**  */
  title: string;

  /**  */
  code: string;

  /**  */
  priority: number;

  /**  */
  full_title: string;

  /**  */
  render_type: EnumHotelUnitGroupAttributeGroupsDtoRenderType;

  /**  */
  show_in_sidebar_filters: boolean;

  /**  */
  title_sidebar: string;

  /**  */
  title_single_page: string;

  /**  */
  max_items_per_row: EnumHotelUnitGroupAttributeGroupsDtoMaxItemsPerRow;

  /**  */
  attributes: HotelUnitGroupAttributeDto[];
}

export interface HotelUnitGroupAttributePrimaryDetailsDtoImpl {
  /**  */
  id: number;
}

export interface PriceDetailsDtoImpl {
  /**  */
  priceWithoutTax: number;

  /**  */
  priceWithTax: number;

  /**  */
  taxAmount?: number;

  /**  */
  taxRate?: number;

  /**  */
  discountRate?: number;

  /**  */
  priceWithoutTaxBeforeDiscount: number;

  /**  */
  priceWithTaxBeforeDiscount: number;
}

export interface HotelUnitGroupRatePlanDtoImpl {
  /**  */
  online: string;

  /**  */
  offline: string;

  /**  */
  walkin: string;
}

export interface ExtendedHotelUnitGroupDto {
  /**  */
  id: string;

  /**  */
  hotelId: string;

  /**  */
  propertyId: string;

  /**  */
  title: string;

  /**  */
  shortDescription: string;

  /**  */
  description: string;

  /**  */
  media: MediaDtoImpl[];

  /**  */
  hotel?: ExtendedHotelDto;

  /**  */
  attributes: HotelUnitGroupAttributePrimaryDetailsDtoImpl[];

  /**  */
  promotionLabels: PromotionLabelDtoImpl[];

  /**  */
  pricePerNightDetails?: PriceDetailsDtoImpl;

  /**  */
  ratePlanId: string;

  /**  */
  maxPersons: number;

  /**  */
  confirmationType: string;

  /**  */
  minStayDays: number;

  /**  */
  minAdvancedBooking: string;

  /**  */
  area: number;

  /**  */
  ratePlans: HotelUnitGroupRatePlanDtoImpl;

  /**  */
  status: string;

  /**  */
  location?: ExtendedLocationDto;

  /**  */
  locationId: string;
}

export interface ExtendedHotelUnitGroupViewTypeDto {
  /**  */
  id: string;

  /**  */
  title: string;

  /**  */
  content_id: number;
}

export interface OfferRatePlanCancellationPolicyDtoImpl {
  /**  */
  cancellationFee: PriceDetailsDtoImpl;

  /**  */
  cancellationDue: string;
}

export interface OfferAdditionalServiceDtoImpl {
  /**  */
  id: string;

  /**  */
  title: string;

  /**  */
  description: string;

  /**  */
  price: PriceDetailsDtoImpl;

  /**  */
  count: number;

  /**  */
  pricingMode?: string;

  /**  */
  startDate?: string;

  /**  */
  endDate?: string;

  /**  */
  serviceType: string;

  /**  */
  availabilityMode?: string;

  /**  */
  postNextDay?: boolean;

  /**  */
  serviceCategory: string;
}

export interface PromoCodeDtoImpl {
  /**  */
  discountIsPercentage: boolean;

  /**  */
  discount: number;
}

export interface OfferRatePlanDtoImpl {
  /**  */
  ratePlanId: string;

  /**  */
  paymentMethod: string;

  /**  */
  stayPriceDetails: PriceDetailsDtoImpl;

  /**  */
  perNightStayPriceDetails: PriceDetailsDtoImpl[];

  /**  */
  pricePerNightDetails: PriceDetailsDtoImpl;

  /**  */
  priceDetails: PriceDetailsDtoImpl;

  /**  */
  cancellationPolicy: OfferRatePlanCancellationPolicyDtoImpl;

  /**  */
  additionalServices: OfferAdditionalServiceDtoImpl[];

  /**  */
  promoCode: PromoCodeDtoImpl;
}

export interface ExtendedOfferDto {
  /**  */
  unitGroupId: string;

  /**  */
  hotelId: string;

  /**  */
  hotel?: ExtendedHotelDto;

  /**  */
  unitGroup?: ExtendedHotelUnitGroupDto;

  /**  */
  ratePlans: OfferRatePlanDtoImpl[];

  /**  */
  arrivalDate: string;

  /**  */
  departureDate: string;

  /**  */
  promoCode: PromoCodeDtoImpl;
}

export interface OfferMetadataDtoImpl {
  /**  */
  minPrice?: number;

  /**  */
  maxPrice?: number;
}

export interface ExtendedSearchOffersResponseDto {
  /**  */
  offers?: ExtendedOfferDto[];

  /**  */
  metadata: OfferMetadataDtoImpl;
}

export interface ExtendedOwnerAccountUnitGroupResponseDto {
  /**  */
  unitGroup?: ExtendedHotelUnitGroupDto;
}

export interface BookingSummaryGuestResponseDtoImpl {
  /**  */
  firstName: string;

  /**  */
  lastName: string;

  /**  */
  email: string;

  /**  */
  passport: string;

  /**  */
  gender: string;

  /**  */
  dateOfBirth: string;

  /**  */
  phone: string;
}

export interface AdditionalServiceDtoImpl {
  /**  */
  id: string;

  /**  */
  title: string;

  /**  */
  description: string;

  /**  */
  booking_id: string;

  /**  */
  serviceDate: Date;

  /**  */
  count: number;

  /**  */
  priceDetails: PriceDetailsDtoImpl;

  /**  */
  pricingMode?: string;

  /**  */
  startDate?: string;

  /**  */
  endDate?: string;

  /**  */
  serviceType: string;

  /**  */
  serviceCategory: string;
}

export interface BookingSummaryResponseDtoImpl {
  /**  */
  id: string;

  /**  */
  arrivalDate: string;

  /**  */
  departureDate: string;

  /**  */
  hotelId: string;

  /**  */
  unitGroupId: string;

  /**  */
  assignedUnit: string;

  /**  */
  adults: number;

  /**  */
  children: number;

  /**  */
  booker: BookingSummaryGuestResponseDtoImpl;

  /**  */
  priceDetails: PriceDetailsDtoImpl;

  /**  */
  paymentMethod: string;

  /**  */
  additionalServices: AdditionalServiceDtoImpl[];

  /**  */
  additionalRequest: string;

  /**  */
  bookingNumber: string;

  /**  */
  bookingStatus: string;

  /**  */
  createBookingMachineStateId: string;

  /**  */
  cancelBookingMachineStateId?: string;

  /**  */
  promoCodeDiscountAmount: number;
}

export interface ExtendedOwnerAccountBookingResponseDto {
  /**  */
  bookingSummary: BookingSummaryResponseDtoImpl;

  /**  */
  unitGroup?: ExtendedHotelUnitGroupDto;

  /**  */
  contract?: object;

  /**  */
  payout?: object;
}
export enum EnumHotelAttributeGroupsDtoMaxItemsPerRow {
  'one' = 'one',
  'two' = 'two',
  'three' = 'three',
  'four' = 'four'
}
export type CombinedSeoMetadataTypes = SEOMetadataDtoImpl;
export enum EnumHotelUnitGroupAttributeGroupsDtoRenderType {
  'Checkbox' = 'Checkbox',
  'Radio' = 'Radio'
}
export enum EnumHotelUnitGroupAttributeGroupsDtoMaxItemsPerRow {
  'one' = 'one',
  'two' = 'two',
  'three' = 'three',
  'four' = 'four'
}
